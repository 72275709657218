<!-- header -->
<!---->
<div class="banner">
    <img id="background-banner" src="assets/logos/Group 4.png"> 
    <img id="background-banner-desktop" src="assets/logos/Rectangle 26.png">    
    <div class="store-wrap">
        <img id="logo" src="assets/logos/lis branco.png">
        <a href="https://play.google.com/store/apps/details?id=com.fcm.casaconectada"> <!-- Link da google play -->
        <img class="stores" id="playstore" src="assets/logos/google play.png">
        </a>
        <a href="https://apps.apple.com/br/app/lis-smart-home/id1507210135"> <!-- Link da app store -->
            <img class="stores" id="applestore" src="assets/logos/app store.png">
        </a>
    </div>
</div>
<!-- Banner com imagem do app LIS -->
<div id="container-banner2">
    <img class="banner2" src="assets/logos/Group 24(1).png">
    <img class="banner2-mobile" src="assets/logos/Group 23.png">
    <img class="banner2-tablet" src="assets/logos/Group 25(1).png">
</div>
<!-- Corpo do site com icones e textos sobre o app -->
<div class="body">
    <div class="container">
        <img class="atributes" src="assets/logos/touch cinza.png">
        <p class="atributes-text">Interface intuitiva</p>
        
        <img class="atributes" src="assets/logos/mesmo fora de casa.png">
        <p class="atributes-text">Controle da sua casa na palma da mão</p>

        <img class="atributes" src="assets/logos/celular.png">
        <p class="atributes-text">Conecte seus acessórios Lis e customize sua experiência!</p>
    </div>
<!-- Banner "Sobre" -->
    <div class="container2" style="margin: -8px;">
    <img class="img-sobre" src="assets/logos/Group 5.png">
    <img class="img-sobre-deskop" src="assets/logos/Group 6.png">
    <img class="img-sobre-tablet" src="assets/logos/Group 26.png">
    </div>
</div>

<!-- Footer com copyright -->
<div class="footer">
    <p class="copyright">Copyright © 2021 ·  FCM IMPORTACAO E EXPORTACAO LTDA.</p>
</div>